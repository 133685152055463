import React, { Component } from "react";
import Loader from 'react-loader-spinner'
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Col, Button } from "react-bootstrap";
import "./Monitor.css"
import Axios from "axios";

export default class HealthCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            environment: this.props.param.environment,
            param: this.props.param,
            status: "loading",
            urls: [],
            healthcheckStat: []
        }
        this.runCheck = this.runCheck.bind(this);
    }

    componentDidMount() {
        this.setState({ urls: this.gatherUrls(this.state.param), status: "ready" });
    }

    gatherUrls(param) {
        const arr = param;
        delete arr.environment;
        delete arr.status;
        delete arr.fileName;
        delete arr.region;
        return Object.values(arr);
    }

    performCheck(url) {
        return Axios.get(url + '/admin/healthcheck')
            .then(res => {
                return {
                    name: res.data.data.type,
                    stat: "healthy"
                }
            }).catch(e => {
                return {
                    name: url.substring(url.indexOf('.com') + 5, url.indexOf('/v1')),
                    stat: "error"
                }
            });
    }

    runCheck(event) {
        event.preventDefault();
        this.setState({ status: "running" });
        const urls = this.state.urls;
        const result = urls.map(url => this.performCheck(url));
        return Promise.all(result).then(res => {
            return this.setState({
                status: "done",
                healthcheckStat: res
            });
        });
    }

    componentState(status) {
        switch (status) {
            case "loading":
                return (
                    <div className="Gathering fullWidth col-12">
                        <Loader
                            type="Oval"
                            color="yellow"
                            height="50"
                            width="50"
                        />
                        <p>Loading...</p>
                    </div>
                )
            case "ready":
                return (
                    <Button variant="success" size="md" disabled={false} block onClick={this.runCheck}>Perform Health Check</Button>
                )
            case "running":
                return (
                    <div className="Gathering fullWidth col-12">
                        <Loader
                            type="Oval"
                            color="green"
                            height="50"
                            width="50"
                        />
                        <p>Performing Health Check...</p>
                    </div>
                )
            case "done":
                return (
                    <div className="col-12">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Services</TableCell>
                                    <TableCell align="center">Condition</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.healthcheckStat.map((data, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {data.name}
                                            </TableCell>
                                            <TableCell align="center"><span className={data.stat}>{data.stat}</span></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                        <Button variant="success" size="md" disabled={false} block onClick={this.runCheck}>Perform Again ?</Button>
                    </div>
                )
            default:
                return (
                    <div className="Gathering fullWidth col-12">
                        <p>System Error, Please Contact System Admin.</p>
                    </div>
                )
        }
    }

    render() {

        const { environment, status } = this.state;

        return (
            <Col className="panel">
                <ExpansionPanel expanded>
                    <ExpansionPanelSummary>
                        <div className="title">
                            <span className="left">Environment:</span>
                            <span className="right">{environment}</span>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {this.componentState(status)}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Col>
        )
    }
}