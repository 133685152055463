import React, { Component } from "react";
import Axios from "axios";
import Loader from 'react-loader-spinner';
import { Redirect } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import { IconContext } from 'react-icons';
import { IoMdHeart, IoMdSearch } from 'react-icons/io';
import Reporting from "./Reporting";
import HealthCheck from "./HealthCheck";
import "./Monitor.css";
import { RequestOption, CheckLogin } from "../lib/authHelper";

export default class Monitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backToLogin: false,
      loading: true,
      failToLoad: false,
      parameters: [],
      healthCheckStats: [],
    };
  }

  componentWillMount() {
    return CheckLogin().then((res) => {
      if (res) {
        return null;
      } else {
        sessionStorage.removeItem("token");
        return this.setState({ backToLogin: true });
      }
    });
  }

  componentDidMount() {
    this.mounted = true;
    return Axios(RequestOption("get", "getStatus", {
      token: sessionStorage.getItem("token")
    })).then((res) => {
      if (this.mounted) return this.setState({ parameters: res.data.status, loading: false });
    }).catch((e) => {
      if (this.mounted) return this.setState({ failToLoad: true, loading: false });
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { loading, failToLoad, backToLogin } = this.state;

    if (backToLogin) {
      return <Redirect to={{
        pathname: '/',
        state: { isLogIn: false, role: "newUser" }
      }} />
    }

    if (loading) {
      return (
        <div className="Loading">
          <Loader
            type="ThreeDots"
            color="#000000"
            height="100"
            width="100"
          />
          <h3>Retriving Data From Server</h3>
        </div>
      )
    }

    if (failToLoad) {
      return (
        <div>
          <h3>Server Error, Please Refreash Browser...</h3>
          <h3>If problem persists, please contact System Admin.</h3>
        </div>
      )
    }

    return (
      <div className="Monitor Report">
        <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
          <Container fluid={true}>
            <Row><h4><IoMdHeart /> Quick Health Check :</h4></Row>
            <Row>
              {this.state.parameters.map(param => {
                return <HealthCheck key={param.environment + "-healthCheck"} param={param} />
              })}
            </Row>
            <br />
            <Row><h4><IoMdSearch />Full Health Report:</h4></Row>
            <Row>
              {this.state.parameters.map(param => {
                return <Reporting key={param.environment + "-Reporting"} param={param}/>
              })}
            </Row>
          </Container>
        </IconContext.Provider>
      </div>
    );
  }
}
