import jwt from "jwt-decode";

export const checkLogIn = () => {
  if (sessionStorage.hasOwnProperty("token")) {
    return true;
  }
  return false;
}

export const checkRole = () => {
  if (sessionStorage.hasOwnProperty("token")) {
    return jwt(sessionStorage.getItem("token")).role;
  }
  return "newUser";
}
