import React, { Component } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  Container,
  Row,
  Col
} from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { AutoLogin, RequestOption } from "../lib/authHelper";
import "./Login.css";
import { checkRole } from "../lib/sessionHelper";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: "",
      username: "",
      password: "",
      willRedirect: false,
      errorMessage: "",
      loginText: "Log In",
      role: "newUser"
    };
  }

  componentWillMount() {
    return AutoLogin().then(res => {
      if (res) {
        return this.setState({ willRedirect: true });
      }
      return null;
    });
  }

  validateForm() {
    return (
      this.state.username.length > 0 &&
      this.state.password.length > 0 &&
      this.state.customerName.length > 0
    );
  }

  handleChange = event => {
    return this.setState({
      [event.target.id]: event.target.value,
      errorMessage: ""
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ loginText: "Loading..." });

    const payload = {
      customerName: this.state.customerName,
      username: this.state.username,
      password: this.state.password
    };

    return Axios(RequestOption("post", "login", { data: payload }))
      .then(response => {
        if (response.status === 200) {
          sessionStorage.setItem("token", response.data.token);
        }
        return this.setState({ willRedirect: true });
      })
      .catch(error => {
        this.setState({ loginText: "Log In" });
        if (error.response.status === 403) {
          return this.setState({ errorMessage: "*Wrong Username or Password" });
        } else if (error.response.status === 404) {
          return this.setState({ errorMessage: "*User Does Not Exist" });
        }
        return this.setState({ errorMessage: "*User Does Not Exist" });
      });
  };

  render() {
    const { willRedirect } = this.state;

    if (willRedirect) {
      return (
        <Redirect
          to={{
            pathname: "/monitor",
            state: { isLogIn: true, role: checkRole() }
          }}
        />
      );
    }

    return (
      <div className="Login">
        <Container>
          <Row>
            <Col>
              <form onSubmit={this.handleSubmit}>
                <FormGroup controlId="customerName" size="large">
                  <FormLabel>Client Name</FormLabel>
                  <FormControl
                    autoFocus
                    type="text"
                    value={this.state.customerName}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup controlId="username" size="large">
                  <FormLabel>Username</FormLabel>
                  <FormControl
                    autoFocus
                    type="text"
                    value={this.state.username}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup controlId="password" size="large">
                  <FormLabel>Password</FormLabel>
                  <FormControl
                    value={this.state.password}
                    onChange={this.handleChange}
                    type="password"
                  />
                  <p>{this.state.errorMessage}</p>
                </FormGroup>
                <Button
                  block
                  size="large"
                  disabled={!this.validateForm()}
                  type="submit"
                >
                  {this.state.loginText}
                </Button>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
