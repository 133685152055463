import React from "react";
import { Row, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

export function statusRow(status, WrappedComponent, ProgressBar, text) {
  return (
    <div>
      <Row>
        <OverlayTrigger
          key={'right'}
          placement={'right'}
          overlay={
            <Tooltip id={`tooltip-${'right'}`}>
              {text}
            </Tooltip>
          }
        >
          <p className="status">
              Status: {status} <WrappedComponent />
          </p>
        </OverlayTrigger>
      </Row>
      <Row>
        <ProgressBar className="bar" />
      </Row>
    </div>
  );
}

export function ButtonState(text, disabled, type, func) {
  return (
    <Button variant={type} size="md" disabled={disabled} block onClick={func}>
      {text}
    </Button>
  );
}
