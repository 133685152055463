import Axios from "axios";
import { RequestOption } from "./authHelper";

export const refreshStatus = () => {
    return Axios(RequestOption("get", "getStatus", {
        token: sessionStorage.getItem("token")
    })).then((res) => {
        return res.data.status;
    }).catch((e) => {
        return alert("Can not retrieve new status from server, please check your internet connection ! :(");
    });
};