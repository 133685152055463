import React, { Component } from "react";
import Axios from "axios";
import Loader from 'react-loader-spinner';
import { Redirect } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { TiUserAddOutline, TiUserDeleteOutline } from 'react-icons/ti';
import Admin from "./Admin";
import AddUser from "./AddUser";
import DeleteUser from "./Delete";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import "./Manage.css";
import { RequestOption, CheckLogin } from "../lib/authHelper";

export default class Manage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backToLogin: false,
      users: [],
      loading: true,
      failToLoad: false,
      admin: {}
    }
  }

  componentWillMount() {
    return CheckLogin().then((res) => {
      if (res) {
        return null;
      } else {
        sessionStorage.removeItem("token");
        return this.setState({ backToLogin: true });
      }
    });
  }

  componentDidMount() {
    this.mounted = true;
    return Axios(RequestOption("get", "getUsers", {
      token: sessionStorage.getItem("token")
    })).then((res) => {
      if (this.mounted) {
        return this.setState({
          users: res.data.users.filter(u => u.role !== 'admin'),
          loading: false,
          admin: res.data.users.find(u => u.role === 'admin'),
        });
      }
    }).catch((e) => {
      if (this.mounted) return this.setState({ failToLoad: true, loading: false });
    });
  }

  render() {
    const { backToLogin, loading, failToLoad, users, admin } = this.state;

    if (backToLogin) {
      return <Redirect to={{
        pathname: '/',
        state: { isLogIn: false, role: "newUser" }
      }} />
    }

    if (loading) {
      return (
        <div className="Loading">
          <Loader
            type="ThreeDots"
            color="#000000"
            height="100"
            width="100"
          />
          <h3>Retriving Data From Server</h3>
        </div>
      )
    }

    if (failToLoad) {
      return (
        <div>
          <h3>Server Error, Please Refreash Browser...</h3>
          <h3>If problem persists, please contact System Admin.</h3>
        </div>
      )
    }

    return (
      <div className="Manage">
        <Container fluid={true} className="fullWidth">
          <Row><h4><TiUserAddOutline />Update / Add User:</h4></Row>
          <Row>
            <Col className="col-lg-6 col-sm-12 col-md-12 col-xs-12 panel">
              <Admin username={admin.username} role={admin.role} />
            </Col>
            <Col className="col-lg-6 col-sm-12 col-md-12 col-xs-12 panel">
              <AddUser />
            </Col>
          </Row>
          <br /><br />
          <Row><h4><TiUserDeleteOutline />Delete User:</h4></Row>
          <Table className="col-12 userTable">
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell align="center">Permissions</TableCell>
                <TableCell align="center">Operation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => {
                return (
                  <DeleteUser key={index} username={user.username} role={user.role} />
                )
              })}
            </TableBody>
          </Table>
        </Container>
      </div>
    );
  }
}