import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Button } from "react-bootstrap";

class ShareButton extends React.Component {

    state = {
        open: false,
        url: this.props.url,
        title: "Link to: " + this.props.filename
    };

    fullWidth = {
        width: '100%'
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    CopyToClipboard = e => {
        this.textArea.select();
        document.execCommand("copy");
        e.target.focus();
        this.setState({ title: "Copied to Clipboard !" });
    };

    render() {
        return (
            <div style={this.fullWidth}>
                <Button variant="primary" onClick={this.handleClickOpen} size="md" style={this.fullWidth}>Generate A Shareable Link</Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="share-link"
                    aria-describedby="allow-user-to-copy-a-link"
                    fullWidth={true}>
                    <DialogTitle id="dialog-title">{this.state.title}</DialogTitle>
                    <DialogContent>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control
                                as="textarea"
                                value={this.state.url}
                                ref={textarea => (this.textArea = textarea)}
                                readOnly
                            />
                        </Form.Group>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.CopyToClipboard} color="primary"> Copy to Clipboard </Button>
                        <Button onClick={this.handleClose} color="primary" autoFocus> Close </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ShareButton;
