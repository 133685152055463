import React, { Component } from "react";
import Axios from "axios";
import passwordValidator from "password-validator";
import { Container, Row, Col, Button } from "react-bootstrap";
import { RequestOption } from "../lib/authHelper";
import { Redirect } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import "./Manage.css";

const schema = new passwordValidator();

schema
  .is()
  .min(8)
  .has()
  .digits()
  .has()
  .not()
  .spaces()
  .has()
  .letters();

export default class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      role: "",
      refresh: false,
      addDisable: false,
      open: false,
      title: "",
      confirmPassword: ""
    };
    this.addUser = this.addUser.bind(this);
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
      title: "Are you sure to add " + this.state.username + " ?"
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  addUser(event) {
    event.preventDefault();
    this.setState({ title: "Adding....", addDisable: true });
    return Axios(
      RequestOption("post", "addUser", {
        token: sessionStorage.getItem("token"),
        data: {
          username: this.state.username,
          password: this.state.password,
          role: this.state.role
        }
      })
    )
      .then(res => this.setState({ refresh: true }))
      .catch(e => this.setState({ title: "Operation Failed!" }));
  }

  validateForm() {
    return (
      this.state.username.length > 0 &&
      this.state.password.length > 7 &&
      this.state.role.length > 0 &&
      this.state.password === this.state.confirmPassword &&
      schema.validate(this.state.password)
    );
  }

  isMatching(){
    if (this.state.password !== this.state.confirmPassword) {
      return "password does not match";
    } else {
      return "";
    }
  }

  render() {
    const { username, refresh, password, confirmPassword } = this.state;

    if (refresh) {
      return <Redirect to="/manage" />;
    }

    return (
      <Col className="col-12">
        <ExpansionPanel expanded>
          <ExpansionPanelSummary>Add a New User</ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Container fluid={true}>
              <Row className="text-left">
                <Col>
                  <TextField
                    required
                    id="outlined-name"
                    label="username"
                    placeholder={username}
                    onChange={this.handleChange("username")}
                    margin="dense"
                    type="username"
                  />
                </Col>
                <Col>
                  <TextField
                    required
                    id="outlined-name"
                    label="password"
                    onChange={this.handleChange("password")}
                    margin="dense"
                    type="password"
                    helperText="min. 8 of mixed letters and numbers or with symbols"
                  />
                </Col>
              </Row>
              <br />
              <Row className="text-justify">
                <Col>
                  <div>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Permission*</FormLabel>
                      <RadioGroup
                        aria-label="Role"
                        name="role"
                        value={this.state.role}
                        onChange={this.handleChange("role")}
                        required
                      >
                        <FormControlLabel
                          value="developer"
                          control={<Radio color="primary"/>}
                          label="Perform & Read"
                          margin="dense"
                        />
                        <FormControlLabel
                          value="readOnlyUser"
                          control={<Radio color="primary" />}
                          label="Read Only"
                          margin="dense"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Col>
                <Col>
                  <TextField
                    required
                    id="outlined-name"
                    label="confirm password"
                    onChange={this.handleChange("confirmPassword")}
                    margin="dense"
                    type="password"
                    error={password === confirmPassword ? false : true}
                    helperText={this.isMatching()}
                  />
                </Col>
              </Row>
              <Row className="text-left">
                <Col>
                  <Button
                    disabled={!this.validateForm()}
                    onClick={this.handleClickOpen}
                  >
                    Submit
                  </Button>
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="add-user"
                    aria-describedby="allow-user-to-add-a-user"
                    fullWidth={true}
                  >
                    <DialogTitle id="dialog-title">
                      {this.state.title}
                    </DialogTitle>
                    <DialogContent />
                    <DialogActions>
                      <Button
                        onClick={this.addUser}
                        disabled={this.state.addDisable}
                        variant="success"
                        autoFocus
                      >
                        Add
                      </Button>
                      <Button onClick={this.handleClose} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Col>
              </Row>
            </Container>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Col>
    );
  }
}
