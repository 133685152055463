import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./containers/Login";
import Logout from "./containers/Logout";
import Monitor from "./containers/Monitor";
import Manage from "./containers/Manage";
import NotFound from "./containers/NotFound";
import Forbidden from "./containers/Forbidden";
import { Authorization } from "./containers/Auth";

export default class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/monitor" component={Authorization(['admin', 'readOnlyUser', 'developer'])(Monitor)} />
        <Route exact path="/manage" component={Authorization(['admin'])(Manage)} />
        <Route exact path="/logout" component={Authorization(['admin', 'readOnlyUser', 'developer'])(Logout)} />
        <Route excat path="/forbid" component={Forbidden} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}
