import React, { Component } from "react";
import Axios from "axios";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { Col, Container, Row, Button } from "react-bootstrap";
import { IconContext } from 'react-icons';
import { MdDirectionsRun, MdError, MdCheckCircle, MdInsertDriveFile, MdSend } from 'react-icons/md';
import { statusRow, ButtonState } from "../lib/statusHelper";
import { InProgressBar, InSuccessBar, InErrorBar, InBufferBar, InUnavailableBar } from '../lib/progressBar';
import ShareButton from '../lib/popupHelper';
import { RequestOption } from "../lib/authHelper";
import { checkRole } from "../lib/sessionHelper";
import { refreshStatus } from "../lib/refreshHelper";
import "./Monitor.css"


export default class Reporting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            param: this.props.param,
            filename: this.props.param.fileName,
            status: this.props.param.status,
            environment: this.props.param.environment,
            url: "",
            fetchMessage: "Fetch Report",
            fetchAble: false,
            showShare: false,
            canDownload: false,
            role: checkRole(),
        }
        this.getTest = this.getTest.bind(this);
        this.runTest = this.runTest.bind(this);
    }

    OnWatch() {
        if (this.state.status === 'running') {
            const timer = setInterval(() => {
                refreshStatus().then((fetched) => {
                    const res = fetched.find((r) => r.environment === this.state.environment);
                    if (res.status === "complete") {
                        clearInterval(timer);
                        return this.setState({ status: res.status, filename: res.fileName, fetchAble: false });
                    } else if (res.status !== "running") {
                        clearInterval(timer);
                        return this.setState({ status: res.status });
                    }
                    return null;
                });
            }, 45000);
            setTimeout(() => {
                if (this.state.status === 'running') {
                    alert("Internal Server Error: Test Program Exited By Timed Out!");
                    clearInterval(timer);
                    return this.setState({ status: "error" });
                }
            }, 600000);
        }
    }

    statusRendering(status) {
        switch (status) {
            case "triggered":
                return statusRow(status, MdSend, InBufferBar, "Waiting for status update.");
            case "running":
                return statusRow(status, MdDirectionsRun, InProgressBar, "Process is running.");
            case "complete":
                return statusRow(status, MdCheckCircle, InSuccessBar, "Test has been completed.");
            case "error":
                return statusRow(status, MdError, InErrorBar, "Error, please check deployment status.");
            case "unavailable":
                return statusRow(status, MdError, InUnavailableBar, "Testing is unavailable for this environment.");
            default:
                return statusRow("System Error", MdError, InErrorBar, "System error, please contact system admin...");
        }
    };

    runButtonState(state) {
        if (this.state.role === "readOnlyUser") return null;
        switch (state) {
            case "triggered":
                return ButtonState("Test is Triggered", true, "warning", this.runTest);
            case "running":
                return ButtonState("Test is Running", true, "success", this.runTest);
            case "complete":
                return ButtonState("Run Test", false, "success", this.runTest);
            case "error":
                return ButtonState("Run Again ?", false, "danger", this.runTest);
            case "unavailable":
                return ButtonState("Server Unavailable", true, "secondary", this.runTest);
            default:
                return ButtonState("Something Went Wrong", false, "error", this.runTest);
        }
    }

    runTest(event) {
        event.preventDefault();
        this.setState({ status: "triggered" });
        return Axios(RequestOption('post', 'trigger', {
            token: sessionStorage.getItem('token'),
            data: { environment: this.state.environment }
        })).then((res) => {
            return this.setState({
                status: "running"
            });
        }).catch(e => {
            return this.setState({
                status: "error"
            });
        });
    }

    getTest(event) {
        event.preventDefault();
        if (!this.state.canDownload) {
            this.setState({ fetchMessage: "Fetching....", fetchAble: true });
            return Axios(RequestOption('get', 'getTest', {
                token: sessionStorage.getItem('token'),
                params: { environment: this.state.environment }
            }))
                .then(res => {
                    return this.setState({
                        url: res.data.link,
                        showShare: true,
                        fetchAble: false,
                        fetchMessage: "Download Report",
                        canDownload: true
                    });
                }).catch(e => {
                    if (e.response.status === 404) {
                        return this.setState({ fetchMessage: "Not Found, Try Again?", fetchAble: false, });
                    }
                    return this.setState({ fetchMessage: "Error, Try Again?", fetchAble: false });
                });
        }
        return window.open(this.state.url, '_blank');
    }

    fetchButtonState(filename) {
        if (filename !== null && filename) {
            return <Button variant="info" size="md" disabled={this.state.fetchAble} block onClick={this.getTest}>{this.state.fetchMessage}</Button>
        }
        return <Button variant="secondary" size="md" disabled={true} block>No Report</Button>
    }

    AddShareButton(showShare) {
        if (showShare) {
            return (
                <Row>
                    <ShareButton url={this.state.url} filename={this.state.filename} />
                </Row>
            )
        }
        return null;
    }

    render() {
        const { filename, status, environment, showShare } = this.state;

        if (status === "running") this.OnWatch();

        return (
            <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
                <Col className="panel">
                    <ExpansionPanel expanded>
                        <ExpansionPanelSummary>
                            <div className="title">
                                <span className="left">Environment:</span>
                                <span className="right">{environment}</span>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Container>
                                {this.statusRendering(status)}
                                <br />
                                <Row>
                                    {this.runButtonState(status)}
                                </Row>
                                <br />
                                <Row>
                                    <div className="title text-left">
                                        <p className="status"><MdInsertDriveFile />Latest Report: </p>
                                        <p className="report">{filename ? filename : 'No Report Recorded'}</p>
                                    </div>
                                </Row>
                                <Row>
                                    {this.fetchButtonState(filename)}
                                </Row>
                                <br />
                                {this.AddShareButton(showShare)}
                            </Container>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Col>
            </IconContext.Provider >
        )
    }
}