import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import Routes from "./Routes";
import { checkLogIn, checkRole } from "./lib/sessionHelper";
import logo from './logo.png';
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogIn: checkLogIn(),
      role: checkRole(),
    }
  }
  
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.location.state) {
      this.setState({ isLogIn: nextProps.location.state.isLogIn });
      this.setState({ role: nextProps.location.state.role });
    }
  }

  Session = (isLogIn) => {
    if (isLogIn) {
      return (
        <React.Fragment>
          <Nav pullright="true">
            <Nav.Link href="logout">Log Out</Nav.Link>
          </Nav>
        </React.Fragment>
      );
    }
    return <div />
  }

  Link = (role, isLogIn) => {
    const defaultItems = (
      <React.Fragment>
        <Nav.Item>
          <Nav.Link href="/monitor" active={this.props.location.pathname === '/monitor'}>Service Monitor</Nav.Link>
        </Nav.Item>
      </React.Fragment>
    )

    if (role === 'admin' && isLogIn) {
      return (
        <React.Fragment>
          {defaultItems}
          <Nav.Item>
            <Nav.Link href="/manage">User Management</Nav.Link>
          </Nav.Item>
        </React.Fragment >
      )
    } else if (isLogIn) {
      return defaultItems;
    }
  }

  render() {
    return (
      <div className="App container-fluid">
        <Navbar bg="light" expand="lg" fixed="top">
          <Navbar.Brand href="/"><img src={logo} className="d-inline-block logo" alt=""/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav variant="tabs" defaultActiveKey={this.props.location.pathname} className="mr-auto">
              {this.Link(this.state.role, this.state.isLogIn)}
            </Nav>
            {this.Session(this.state.isLogIn)}
          </Navbar.Collapse>
        </Navbar>
        <Routes />
      </div>
    );
  }
}

export default withRouter(App);
