import React, { Component } from "react";
import Axios from "axios";
import passwordValidator from "password-validator";
import { Container, Row, Col, Button } from "react-bootstrap";
import { RequestOption } from "../lib/authHelper";
import { Redirect } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import "./Manage.css";

const schema = new passwordValidator();

schema
  .is()
  .min(8)
  .has()
  .digits()
  .has()
  .not()
  .spaces()
  .has()
  .letters();

export default class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      role: this.props.role,
      password: "",
      confirmPassword: "",
      refresh: false,
      updateDisable: false,
      open: false,
      title: "",
      notMatch: false
    };
    this.updateAdmin = this.updateAdmin.bind(this);
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
      title: "Are you sure to update the admin profile ?"
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  updateAdmin(event) {
    event.preventDefault();
    this.setState({ title: "Updating....", addDisable: true });

    return Axios(
      RequestOption("post", "addUser", {
        token: sessionStorage.getItem("token"),
        data: {
          username: this.state.username,
          password: this.state.password,
          role: this.state.role
        }
      })
    )
      .then(res => this.setState({ refresh: true }))
      .catch(e =>
        this.setState({ title: "Operation Failed! Try Again Later" })
      );
  }

  validateForm() {
    return (
      this.state.username.length > 0 &&
      this.state.password.length > 7 &&
      this.state.confirmPassword.length > 7 &&
      this.state.role.length > 0 &&
      this.state.password === this.state.confirmPassword &&
      schema.validate(this.state.password)
    );
  }

  isMatching(){
    if (this.state.password !== this.state.confirmPassword) {
      return "password does not match";
    } else {
      return "";
    }
  }

  render() {
    const { username, refresh, password, confirmPassword } = this.state;

    if (refresh) {
      return <Redirect to="/manage" />;
    }

    return (
      <Col className="col-12">
        <ExpansionPanel expanded>
          <ExpansionPanelSummary>
            Update Administrator Profile
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Container fluid={true}>
              <Row className="text-left">
                <Col>
                  <TextField
                    required
                    id="outlined-name"
                    label="username"
                    placeholder={username}
                    onChange={this.handleChange("username")}
                    margin="dense"
                    type="username"
                  />
                </Col>
                <Col>
                  <TextField
                    required
                    id="outlined-name"
                    label="password"
                    onChange={this.handleChange("password")}
                    margin="dense"
                    type="password"
                    helperText="min. 8 of mixed letters and numbers or with symbols"
                  />
                </Col>
              </Row>
              <br />
              <Row className="text-justify">
                <Col>
                  <TextField
                    required
                    id="outlined-name"
                    label="confirm password"
                    onChange={this.handleChange("confirmPassword")}
                    margin="dense"
                    type="password"
                    error={(password === confirmPassword ? false : true)}
                    helperText={this.isMatching()}
                  />
                </Col>
                <Col>
                  <Button
                    disabled={!this.validateForm()}
                    onClick={this.handleClickOpen}
                  >
                    Update
                  </Button>
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="update-admin"
                    aria-describedby="allow-user-to-add-a-user"
                    fullWidth={true}
                  >
                    <DialogTitle id="dialog-title">
                      {this.state.title}
                    </DialogTitle>
                    <DialogContent />
                    <DialogActions>
                      <Button
                        onClick={this.updateAdmin}
                        disabled={this.state.updateDisable}
                        variant="success"
                        autoFocus
                      >
                        Update
                      </Button>
                      <Button onClick={this.handleClose} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Col>
              </Row>
            </Container>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Col>
    );
  }
}
