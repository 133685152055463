import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./NotFound.css";

export default class NotFound extends Component {

  constructor() {
    super();
    this.state = {
      countdown: 5
    }
  }

  timer = '';
  counter = '';

  componentDidMount() {
    ((timeleft) => {
      this.counter = setInterval(() => {
        this.setState({ countdown: timeleft });
        timeleft -= 1;
      }, 1000);
    })(5);
  }

  componentWillUnmount() {
    clearInterval(this.counter);
  }

  render() {
    if (this.state.countdown <= 0) {
      return <Redirect to='/' />
    }
    return (
      <div className="NotFound">
        <h3>Sorry, page not found!</h3>
        <p>Redirecting you to log in page in {this.state.countdown} sec.</p>
      </div>
    );
  }
}
