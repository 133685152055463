import React from "react";
import { checkRole } from "../lib/sessionHelper";
import { Redirect } from "react-router-dom";

export const Authorization = allowedRoles => WrappedComponent => {
  return class WithAuthorization extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        role: checkRole()
      };
    }

    render() {
      if (allowedRoles.includes(this.state.role)) {
        return <WrappedComponent {...this.props} />;
      } else {
        return <Redirect to={{
          pathname: '/forbid',
        }} />;
      }
    }
  };
};
