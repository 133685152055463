import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { clearToken } from "../lib/authHelper";

export default class Home extends Component {
    constructor(props) {
        super(props);
        clearToken();
    }

    render() {
        return <Redirect to={{
            pathname: '/',
            state: { isLogIn: false, role: "newUser" }
        }} />
    }
}
