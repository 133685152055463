import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { lightGreen, red, green, yellow, grey } from '@material-ui/core/colors';

const ColorTheme = (state) => {

    let palette = {};

    if (state === "triggered") palette.primary = yellow;
    if (state === "warning") palette.primary = lightGreen;
    if (state === "error") palette.primary = red;
    if (state === "success") palette.primary = green;
    if (state === "unavailable") palette.primary = grey;

    return createMuiTheme({
        typography: {
            fontFamily: [
                "Roboto",
                "-apple-system",
                "BlinkMacSystemFont",
                "Segoe UI",
                "Arial",
                "sans-serif"
            ].join(","),
            useNextVariants: true
        },
        palette: palette,
        useNextVariants: true
    });
}

const styles = {
    root: {
        flexGrow: 1,
    },
};

const Progress = (type, state) => {
    return (props) => {
        const { classes } = props;
        if (type === "buffer") {
            return (
                <div className={classes.root}>
                    <MuiThemeProvider theme={ColorTheme(state)}>
                        <LinearProgress variant={type} value={0} valueBuffer={0}/>
                    </MuiThemeProvider>
                </div>
            );
        }
        return (
            <div className={classes.root}>
                <MuiThemeProvider theme={ColorTheme(state)}>
                    <LinearProgress variant={type} value={100} />
                </MuiThemeProvider>
            </div>
        );
    }
}

export const InProgressBar = withStyles(styles)(Progress("indeterminate", "warning"));
export const InSuccessBar = withStyles(styles)(Progress("determinate", "success"));
export const InErrorBar = withStyles(styles)(Progress("determinate", "error"));
export const InBufferBar = withStyles(styles)(Progress("query", "triggered"));
export const InUnavailableBar = withStyles(styles)(Progress("determinate", "unavailable"));

