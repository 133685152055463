import Axios from "axios";

const apiBaseUrl = (process.env.REACT_APP_environment === 'Prod') ? process.env.REACT_APP_PROD_API : process.env.REACT_APP_DEV_API;

export const RequestOption = (method, path, options) => {
  const request = {
    method: method,
    url: `${apiBaseUrl}${path}`,
    timeout: 6000,
    headers: {
      "Content-Type": "application/json",
    }
  };
  if (options.token) {
    request.headers.Authorization = `Bearer ${options.token}`;
  }
  if (options.data) {
    request.data = options.data;
  }
  if (options.params) {
    request.params = options.params;
  }
  return request;
}

export const AutoLogin = () => {
  if (sessionStorage.hasOwnProperty("token")) {
    return Axios(
      RequestOption("get", "tokenLogin", {
        token: sessionStorage.getItem("token")
      })
    ).then(response => {
      if (response.status === 200) {
        sessionStorage.setItem("token", response.data.token);
        return true;
      }
      return false;
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          alert("Error: " + error.response.data.message);
        } else {
          alert("Error: Profile Might Be Updated, Token Expired!");
        }
        sessionStorage.removeItem("token");
      }
      return false;
    });
  }
  return Promise.resolve(false);
}

export const CheckLogin = () => {
  if (sessionStorage.hasOwnProperty("token")) {
    return Axios(
      RequestOption("get", "tokenLogin", {
        token: sessionStorage.getItem("token")
      })
    ).then(response => {
      if (response.status === 200) {
        sessionStorage.setItem("token", response.data.token);
        return true;
      }
      return false;
    }).catch(error => {
      alert("Profile might be updated, please login with new credentials!");
      return false;
    });
  }
  return Promise.resolve(false);
}

export const clearToken = () => {
  return sessionStorage.removeItem("token");
}
