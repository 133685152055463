import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Redirect } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "react-bootstrap";
import Axios from "axios";
import { RequestOption } from "../lib/authHelper";

export default class DeleteUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      role: this.props.role,
      refresh: false,
      open: false,
      title: "Are you sure to delete " + this.props.username + "?",
      deleteDisable: false
    };
    this.deleteUser = this.deleteUser.bind(this);
  }

  fullWidth = {
    width: "100%"
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  deleteUser() {
    this.setState({ title: "Deleting....", deleteDisable: true });
    return Axios(
      RequestOption("delete", "deleteUser", {
        token: sessionStorage.getItem("token"),
        params: { username: this.state.username }
      })
    )
      .then(res => this.setState({ refresh: true }))
      .catch(e =>
        this.setState({ title: "Operation Failed! Please Try Again Later..." })
      );
  }

  render() {
    const { username, role, refresh } = this.state;

    if (refresh) {
      return <Redirect to="/manage" />;
    }

    return (
      <TableRow>
        <TableCell component="th" scope="row">
          {username}
        </TableCell>
        <TableCell align="center">
          <span className={role}>
            {role === "developer" ? "Perform & Read" : "Read Only"}
          </span>
        </TableCell>
        <TableCell align="center">
          <Button variant="danger" size="sm" onClick={this.handleClickOpen}>
            Delete
          </Button>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="share-link"
            aria-describedby="allow-user-to-copy-a-link"
            fullWidth={true}
          >
            <DialogTitle id="dialog-title">{this.state.title}</DialogTitle>
            <DialogContent />
            <DialogActions>
              <Button
                onClick={this.deleteUser}
                disabled={this.state.deleteDisable}
                variant="danger"
              >
                Delete
              </Button>
              <Button onClick={this.handleClose} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </TableCell>
      </TableRow>
    );
  }
}
